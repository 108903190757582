/**THEMES**/
:root, .light {
  --white: #f8f4EE;
  --black: #131313;
  --grey: rgba(19, 19, 19, 60%);
  --beige: rgba(19, 19, 19, 30%);
  --bgColor: #f8f4ee;
  --primaryText: #131313;
}

.dark {
  --white: #121212;
  --black: rgba(248, 244, 238, 100%);
  --grey: rgba(248, 244, 238, 60%);
  --beige: rgba(248, 244, 238, 60%);
  --bgColor: #121212;
  --primaryText: rgba(248, 244, 238, 75%);
}
.dark .notch {
  transform: translate(1rem, 0);
}
.dark .notchMoon {
  opacity: 1;
}

html {
  scroll-behavior: smooth;
}
body {
  font-family: 'Roboto Mono', monospace;
  background-color: var(--bgColor);
  color: var(--primaryText); 
  transition: background-color 0.6s ease, color 1s ease;
}
.home {
  padding: 0 6rem 0 6rem;
  margin: 0;  
}


/**BUTTONS**/
a {
  text-decoration: none;
  color: var(--grey);
  transition: all ease 1s;
}
.menu div a:hover, p a:hover{
  text-decoration: underline 0.15em var(--grey);
}

.btn {
  cursor: pointer;
}

.btnClear {
  border: 1px solid var(--black); 
  padding: 0.75rem;
  font-size: 1rem;
  width: fit-content;
  transition: background-color 0.3s ease;
}
.btnClear:hover {
  background-color: var(--primaryText);  
  color: var(--bgColor);
}

.projectTitle a svg{
  margin: 0;
  padding: 0;
  font-size: 1rem;
  color: var(--grey);
}


/**HEADER**/
.menu{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 2rem;
  -webkit-animation: slide-in-top 0.6s 0.6s both;
  animation: slide-in-top 0.6s 0.6s both;
}
.menu div {
  margin-left: 3rem;
  text-transform: lowercase;
}
.menu a {
  color: var(--primaryText);

}
.header {
  position: absolute;
  top: 0rem;
  left: 1rem;
	-webkit-animation: slide-in-left 0.6s 0.1s both;
	        animation: slide-in-left 0.6s 0.1s both;
}
.header img {
  width: 8rem;
}

.toggle {
  height: 1.6rem;
  width: 2.5rem;
  border-radius: 15rem;
  background-color: var(--black);
  position: relative;
  cursor: pointer;
}
.notch {
  height: 1.2rem;
  width: 1.2rem;
  border-radius: 50%;
  background-color: var(--white);
  position: absolute;
  top: 3px;
  right: 18px;
  transition: all 0.3s ease;
}
.notchMoon {
  height: 1.2rem;
  width: 1.2rem;
  border-radius: 50%;
  background-color: var(--black);
  position: absolute;
  top: 3px;
  right: 11px;
  opacity: 0;
}

.headerBlock {
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  padding: 0 6rem 0 6rem;
  }
.headerBlock .homeMainText {
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 1.5;
  -webkit-animation: fade-in-bottom 0.7s 1.7s both;
  animation: fade-in-bottom 0.7s 1.7s both;
}
.headerBlock .homeSubText {
  font-size: 1.75rem;
  line-height: 1.75;
  padding-top: 1rem;
  -webkit-animation: fade-in-bottom 0.7s 2s both;
  animation: fade-in-bottom 0.7s 2s both;
}

.hamburger {
  display: none;
}

.line {
  width: 2rem;
  height: 2px;
  margin-bottom: 0.5rem;
  background-color: var(--primaryText);
  /* transform-origin: 1px;
  transition: all 0.3s linear; */
}

/**CONTAINERS**/
.contentBlock {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 5rem;
  margin-bottom: 5rem;
  opacity: 0;
  transition: opacity 500ms;
}
.contentBlock.scrolled {
  opacity: 1;
}
.blockLabel {
  margin-right: 4rem;
  width: 100%;
  max-width: 12rem;
  font-size: 2rem;
  text-align: right;
  font-weight: 700;
  display: flex;
  flex-direction: column;
}
.blockLabel .contentCount {
  color: var(--beige);
  font-size: 1.25rem;
  margin-bottom: 1rem;
}
.blockContent {
  width: 100%;
  max-width: 50rem;
}


/**CONTENT BLOCKS**/
p {
  margin-top: 0;
  line-height: 1.5;
}
ul li {
  list-style: none;
}
p, li {
  font-size: 1.5rem;
}

.comment {
  font-size: 1.2rem;
}

.skills {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.skill {
  margin-bottom: 2rem;
}
.skillTitle {
  font-size: 1.5rem;
  font-weight: 600;
}
.skill ul {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}
.skillTitle+ul li {
  width: fit-content;
  border: 1px solid var(--grey);
  border-radius: 2px;
  padding: 0.25rem 0.5rem;
  margin-right: 0.75rem;
  margin-bottom: 1rem;
  color: var(--grey);
}

.job, .degree {
  display: flex; 
  justify-content: space-between;
}
.jobInfo, .degreeInfo {
  padding-bottom: 1rem;
}
.jobTitle, .degreeSchool {
  font-size: 1.5rem;
  font-weight: 600;
}
.jobCompany, .degreeTitle {
  font-size: 1.3rem;
  color: var(--grey);
}
.jobDate, .degreeDate {
  font-size: 1.2rem;
}

.projects {
  display: flex;
  flex-direction: column;
}
.project {
  margin-bottom: 3rem;
  border: 1px solid var(--grey);
  border-radius: 2px;
}
.projectTitle {
  font-size: 1.5rem;
  font-weight: 600;
  border-bottom: 1px solid var(--grey);
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
  color: var(--primaryText);
}
a .projectTitle svg {
  transition: transform ease-out 0.1s;
}
a .projectTitle svg:hover {
  transform: scale(1.3);
}
.projectTechList {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}
.projectTechList div {
  border: 1px solid var(--grey);
  border-radius: 2px;
  padding: 0.25rem 0.5rem;
  margin-right: 0.75rem;
  margin-bottom: 1rem;
  color: var(--grey);
}
.projectTitle, .projectDescription, .projectTechList {
  padding: 1rem;
}


/**FOOTER**/
.footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 2rem;
}
.footerLabel {
  font-size: 1.25rem;
  padding-right: 2rem;
}
.footerLinks a{
  margin-left: 1.5rem;
  font-size: 2rem;
  color: var(--primaryText);
  transition: transform ease 1s;
}
.footerLinks a svg:hover {
  transform: scale(1.1);
}

.floating {
  position: fixed;
  bottom: 0.5rem;
  right: 1rem;
  font-size: 2rem;
	-webkit-animation: bounce-in-fwd 1.1s both;
	        animation: bounce-in-fwd 1.1s both;
}
.floating a {
  color: var(--primaryText);
  transition: transform ease 1s;
}
.floating a svg:hover {
  transform: scale(1.1);
}


 @-webkit-keyframes bounce-in-fwd {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: scale(0.7);
            transform: scale(0.7);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: scale(0.84);
            transform: scale(0.84);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  89% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
@keyframes bounce-in-fwd {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: scale(0.7);
            transform: scale(0.7);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: scale(0.84);
            transform: scale(0.84);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  89% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}

 @-webkit-keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
 @-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

@media screen and (max-width: 1250px) {
  .headerBlock {
    padding: 0 2rem 0 2rem;
  }
  .blockLabel {
    margin-right: 0;
    text-align: center;
    padding-top: 0;
    margin-bottom: 3rem;
  }
  .contentCount {
    visibility: hidden;
  }
  .contentBlock {
    padding-top: 0;
  }
}

@media screen and (max-width: 992px) {
  .home {
    padding: 0 2.25rem 0 2.25rem;
  }
  
  p, li {
    font-size: 1.2rem;
  }
  .header {
    left: 0.1rem;
  }
  .header img {
    transform: scale(0.8);
  }
  
  .headerBlock {
    padding: 0;
  }
  .headerBlock .homeMainText {
    font-size: 1.3rem;
    -webkit-animation: slide-in-left 0.6s 0.3s both;
    animation: slide-in-left 0.6s 0.3s both;
  }
  .headerBlock .homeSubText {
    font-size: 1.2rem;
    -webkit-animation: slide-in-left 0.6s 0.3s both;
    animation: slide-in-left 0.6s 0.3s both;
  }

  .hamburger {
    display: block;
    position: absolute;
    top: 25px;
    right: 25px;
    z-index: 10;
    cursor: pointer;
  }
  .line {
    transition: all 0.2s ease-in-out;
  }
  .hamburger.menuX .line:nth-child(1) {
    transform: rotate(-45deg);
    margin-top: 25px;
  }
  .hamburger.menuX .line:nth-child(2) {
    transform: rotate(45deg);
    margin-top: -10px;
  }
  .menu {
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    margin-right: 5rem;
    height: 100vh;
    -webkit-animation: slide-in-right 0.3s 0.1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-right 0.3s 0.1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }
  .hideMenu {
    display: none;
  }
  .menu div {
    margin-bottom: 2rem;
  }

  .contentBlock {
    margin-bottom: 1.5rem;
  }
  .comment, .footerLabel {
    font-size: 1rem;
  }

  .job {
    flex-direction: column-reverse;
  }  
  .jobInfo {
    padding-bottom: 2rem;
  }
  .jobTitle {
    font-size: 1.3rem;
  }
  .jobCompany {
    margin-bottom: 0;
  }
  .jobDate {
    font-size: 1.1rem;
    padding-bottom: 0.5rem;
  }
  
  .skillTitle, .projectTitle, .degreeSchool {
    font-size: 1.3rem;
  }
  .degreeTitle {
    font-size: 1.1rem;
  }
  .footer {
    flex-direction: column;
  }
  .footerLinks a {
    margin-left: 0;
    margin-right: 1.5rem;
  }
  .footerLinks a svg {
    font-size: 1.3rem;
  }

  .floating {
    bottom: 3rem;
    right: 0.5rem;
  }
  .floating a svg{
    transform: scale(1);
  }
  .floating a svg:hover{
    transform: scale(1);
  }
}
